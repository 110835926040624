<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="false"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-box-full'"
    :title="pageTitle"
    :total="total"
    :filtered="filtered"
  >
    <template v-slot:headerAction>
      <div>
        <b-button
          squared
          variant="warning"
          class="icon btn-icon mr-1"
          v-if="!backTo"
          @click="filtered = !filtered"
          v-b-tooltip.hover="'Filtrele'"
        >
          <i class="fad fa-filter" />
        </b-button>
        <b-button squared variant="success" v-if="!backTo" @click="handlerAddNew" v-b-tooltip.hover="'Yeni Kayıt'">
          <i class="fad fa-plus" /> YENİ KAYIT
        </b-button>
        <b-button squared variant="secondary" v-else @click="handlerBackTo" v-b-tooltip.hover="'Geri'">
          <i class="fad fa-angle-left" /> GERİ
        </b-button>
        <b-button-group class="pl-1">
          <!-- <b-button squared variant="outline-secondary" @click="handlerItemView('list-view')" v-b-tooltip.hover="'Liste'">
            <i class="fad fa-list"></i>
          </b-button>
          <b-button squared variant="outline-secondary" @click="handlerItemView('gird-view')" v-b-tooltip.hover="'Grid'">
            <i class="fad fa-th-large"></i>
          </b-button> -->
          <b-button squared variant="info" class="mr-2" v-if="!backTo" @click="handlerUrunSirala" v-b-tooltip.hover="'Ürün Sirala'">
            <i class="fad fa-sort" />
          </b-button>
          <b-button
            squared
            variant="primary"
            v-if="!backTo"
            @click="handlerUrunMaliyetIsle"
            v-b-tooltip.hover="'Ürün Maliyet ve Satış Fiyat İşle'"
          >
            <i class="fad fa-money-check-edit-alt" />
          </b-button>
        </b-button-group>
      </div>
    </template>
    <template v-slot:body>
      <transition mode="out-in">
        <router-view
          @total="total = $event"
          :filtered="filtered"
          @filtered="handlerFiltered($event)"
          :itemView="itemView"
          @show="show = $event"
          @backTo="backTo = $event"
          @pageTitle="pageTitle = $event"
        />
      </transition>
    </template>
  </BTableCard>
</template>
<script>
import store from '@/store';
import { useRouter } from '@/libs/utils';
import BTableCard from '@/components/cards/BTableCard.vue';
import { computed, defineComponent, ref, onMounted, watch } from '@vue/composition-api';

export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);
    expo.filtered = ref(false);
    expo.itemView = computed(() => store.getters.getItemView);
    expo.backTo = ref(false);
    expo.total = ref(0);
    expo.pageTitle = ref('Ürünler');

    onMounted(async () => {
      expo.show.value = true;
      await handlerFetchAllData();
      expo.backTo.value = false;
    });

    const handlerFetchAllData = async () => {
      const statu = { statu: true };
      await store.dispatch('kategoriListele', { ...statu });
      await store.dispatch('bolgeListele', statu);
      await store.dispatch('kampanyaListele', statu);
      await store.dispatch('variantGrupListele', statu);
      await store.dispatch('urunOzelAlanListele', statu);
      await store.dispatch('uyeGrupListele', statu);
      await store.dispatch('kurAyarlariGetir');
      await store.dispatch('ekstraHizmetListele', { ...statu, urun_grubu: ['villa', 'yat', 'tur', 'arac_kira'] });
    };

    expo.handlerFiltered = (event) => {
      expo.filtered.value = event;
    };

    expo.handlerAddNew = () => {
      expo.total.value = 0;
      expo.backTo.value = true;
      route.value.name != 'urun-ekle' && router.push({ name: 'urun-ekle' });
    };

    expo.handlerUrunSirala = () => {
      expo.total.value = 0;
      expo.backTo.value = true;
      route.value.name != 'urun-sirala' && router.push({ name: 'urun-sirala' });
    };

    expo.handlerUrunMaliyetIsle = () => {
      expo.total.value = 0;
      expo.backTo.value = true;
      route.value.name != 'urun-maliyet-fiyat' && router.push({ name: 'urun-maliyet-fiyat' });
    };

    expo.handlerItemView = (event) => {
      store.commit('TOGGLE_ITEM_VIEW', event);
    };

    expo.handlerBackTo = () => {
      expo.backTo.value = false;
      router.push({ name: 'urun-listele' });
    };

    watch(route, (val) => {
      if (val.hash != '#' && val.name == 'urun-listele') expo.backTo.value = false;
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
